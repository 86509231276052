
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {Field} from "vee-validate";
import {MetricSelect, TeamSelect} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import WidgetService from "@/core/services/WidgetService";
import ImageLargeUpload from "@/components/base/upload/ImageLargeUpload.vue";
import WidgetPreview from "@/views/widget/WidgetPreview.vue";

export default defineComponent({
  name: "WidgetForm",
  components: {WidgetPreview, ImageLargeUpload, BaseSelect, BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    id: {type: String},
    widget: {}
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref(props.widget);

    watch(() => props.widget, cb => {
      model.value = cb;
    })
    return {
      submitting,
      model,
      ...MetricSelect(),
      ...TeamSelect(),
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      if (this.id) {
        WidgetService.update(this.id, this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      } else {
        WidgetService.create(this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
