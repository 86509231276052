
import {computed, defineComponent, onUpdated, watch} from "vue";
import {useGroupStore} from "@/store/group.store";
import {PinaLoadEntity} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Widgets from "@/views/widget/Widgets.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Dashboard",
  components: {Widgets, QuickAction},
  props: {
    dashboardId: {}
  },
  setup() {
    const groupStore = useGroupStore();
    const entity = computed<any>(() => groupStore.entity);

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Dashboard', [
        {link: false, router: '', text: entity.value.name}
      ])
    })

    watch(entity, () => {
      setCurrentPageBreadcrumbsWithParams('Dashboard', [
        {link: false, router: '', text: entity.value.name}
      ])
    })

    return {
      entity,
      ...PinaLoadEntity(groupStore)
    }
  },
  watch: {
    dashboardId(cb) {
      this.reloadEntityWithId(cb);
    }
  },
  methods: {

    add() {
      const widgets = this.$refs.widgets as typeof Widgets;
      widgets.addWidget();
    }
  }
})
