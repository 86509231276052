
import {defineComponent, PropType, ref} from "vue";

export default defineComponent({
  name: "LineChart",
  props: {
    categories: {type: Array as PropType<any>, required: true},
    data: {type: Array as PropType<any>, required: true},
    height: {default: 200},
  },
  setup(props) {

    const options = ref<any>({
      chart: {
        height: props.height,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
      }
    })
    const buildOptions = (categories) => {
      options.value.xaxis.categories = categories;
    }
    buildOptions(props.categories);
    return {
      options,
    }
  }
})
