
import {defineComponent} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";

export default defineComponent({
  name: "ImageWidget",
  components: {QuickAction},

  props: {
    editable: {default: false},
    widget: {type: Object}
  },
  emits: ['edit', 'delete'],
  setup(_,{emit}) {
    const onEdit = () => {
      emit('edit')
    }
    const onDelete = () => {
      emit('delete')
    }
    return {
      onEdit,
      onDelete,
    }
  }
})
