import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-stack" }
const _hoisted_2 = { class: "fs-2 fw-bold my-2" }
const _hoisted_3 = { class: "d-flex flex-wrap my-1" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { class: "menu-item px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_Widgets = _resolveComponent("Widgets")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.entity.name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.entity?.access?.canAddWidget)
            ? (_openBlock(), _createBlock(_component_QuickAction, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", {
                      class: "menu-link px-3",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)))
                    }, "Add Widget")
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_Widgets, {
      "dashboard-id": _ctx.dashboardId,
      editable: true,
      ref: "widgets"
    }, null, 8, ["dashboard-id"])
  ], 64))
}