import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-xl-stretch mb-5 mb-xl-8" }
const _hoisted_2 = { class: "card-header border-0 py-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "menu-item px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.widget.name), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.editable)
          ? (_openBlock(), _createBlock(_component_QuickAction, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", {
                    class: "menu-link px-3",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                  }, "Edit"),
                  _createElementVNode("span", {
                    class: "menu-link px-3 text-danger",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDelete && _ctx.onDelete(...args)))
                  }, "Delete")
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", {
      class: "card-body p-0 bgi-no-repeat h-250px",
      style: _normalizeStyle({ backgroundImage: `url(${_ctx.widget.image})`, 'background-size': 'contain' })
    }, null, 4)
  ]))
}