
import {defineComponent, PropType, ref} from "vue";

export default defineComponent({
  name: "BarChart",
  props: {
    chartHeight: {default: 200},
    chartColor: {default: 'info'},
    categories: {type: Array as PropType<any>, required: true},
    data: {type: Array as PropType<any>, required: true},
  },
  setup(props) {
    const series = ref<any>([])
    const chartOptions = ref<any>({
      chart: {
        fontFamily: "inherit",
        type: 'bar',
        height: props.chartHeight
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      }
    })

    const buildCharOptions = (categories: any[]) => {
      chartOptions.value.xaxis.categories = categories;
    }
    const buildSeries = (seriesData: any[]) => {
      series.value = seriesData
    }
    buildCharOptions(props.categories);
    buildSeries(props.data);
    return {
      chartOptions,
      series
    }
  }
})
