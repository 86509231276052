
import {defineComponent, PropType, ref, watch} from "vue";

export default defineComponent({
  name: "ColumnChart",
  props: {
    data: {type: Array as PropType<any>, required: true},
    categories: {type: Array as PropType<any>, required: true},
    height: {default: 200},
  },
  setup(props) {
    const chartOptions = ref<any>({
      chart: {
        type: 'bar',
        height: props.height,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      xaxis: {
        categories: [],
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    })


    const buildCategories = (categories) => {
      chartOptions.value.xaxis.categories = categories;
    }
    buildCategories(props.categories);
    watch(() => props.categories, cb => {
      buildCategories(cb);
    })
    return {
      chartOptions,
    }
  }
})
