
import {computed, defineComponent, ref, watch} from "vue";
import {useWidgetStore} from "@/store/widget.store";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import WidgetForm from "@/views/widget/WidgetForm.vue";
import BaseWidget from "@/views/widget/BaseWidget.vue";
import Swal from "sweetalert2";
import WidgetService from "@/core/services/WidgetService";

export default defineComponent({
  name: "Widgets",
  components: {BaseWidget, WidgetForm, BaseModal},
  props: {
    dashboardId: {type: String, required: true},
    editable: {type: Boolean, default: false},
  },
  setup(props) {
    const state = ref({
      modalName: '',
      model: {
        name: '',
        type: 'METRIC',
        dashboardIdRequest: props.dashboardId,
        metricIdRequest: '',
        image: '',
        widgetConfig: {size: 3},
        chartConfig: {type: 'line'}
      },
      widgetId: ''
    })
    const widgetStore = useWidgetStore();
    const widgets = computed(() => widgetStore.list);
    const loadWidgets = (dashboardId: string) => {
      widgetStore.getList({dashboardId: dashboardId})
    }
    loadWidgets(props.dashboardId)
    watch(() => props.dashboardId, (cb: string) => {
      loadWidgets(cb);
    })
    return {
      loadWidgets,
      state,
      widgets,
    }
  },
  methods: {
    onDelete(widget) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await WidgetService.delete(widget.id).then(() => {
            this.loadWidgets(this.dashboardId)
          })
        }
      })
    },
    onEdit(widget) {
      this.state = {
        modalName: 'Edit  Widget',
        model: {
          name: widget.name,
          type: widget.type,
          dashboardIdRequest: this.dashboardId,
          metricIdRequest: widget.metricId,
          image: widget.image,
          chartConfig: widget.chartConfig ? widget.chartConfig : {type: 'line'},
          widgetConfig: widget.widgetConfig ? widget.widgetConfig : {size: 3}
        },
        widgetId: widget.id
      }
      const modal = this.$refs.widgetFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    addWidget() {
      this.state = {
        modalName: 'Add Widget',
        model: {
          name: '',
          type: 'METRIC',
          dashboardIdRequest: this.dashboardId,
          metricIdRequest: '',
          image: '',
          widgetConfig: {size: 3},
          chartConfig: {type: 'line'}
        },
        widgetId: ''
      }
      const modal = this.$refs.widgetFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSavedResponse() {
      this.loadWidgets(this.dashboardId)
      const modal = this.$refs.widgetFormRef as typeof BaseModal;
      modal.hideBaseModal();
    }
  }
})
