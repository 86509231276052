import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_AreaChart = _resolveComponent("AreaChart")!
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_ColumnChart = _resolveComponent("ColumnChart")!
  const _component_CalendarWidget = _resolveComponent("CalendarWidget")!

  return (_ctx.widget.type ==='METRIC')
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.widget.chartConfig.type ==='line' && _ctx.metric.id)
          ? (_openBlock(), _createBlock(_component_LineChart, {
              key: 0,
              categories: _ctx.metric.chart.categories,
              data: _ctx.metric.chart.data
            }, null, 8, ["categories", "data"]))
          : _createCommentVNode("", true),
        (_ctx.widget.chartConfig.type ==='area' && _ctx.metric.id)
          ? (_openBlock(), _createBlock(_component_AreaChart, {
              key: 1,
              categories: _ctx.metric.chart.categories,
              data: _ctx.metric.chart.data,
              "chart-color": "success"
            }, null, 8, ["categories", "data"]))
          : _createCommentVNode("", true),
        (_ctx.widget.chartConfig.type ==='bar' && _ctx.metric.id)
          ? (_openBlock(), _createBlock(_component_BarChart, {
              key: 2,
              categories: _ctx.metric.chart.categories,
              data: _ctx.metric.chart.data
            }, null, 8, ["categories", "data"]))
          : _createCommentVNode("", true),
        (_ctx.widget.chartConfig.type ==='column' && _ctx.metric.id)
          ? (_openBlock(), _createBlock(_component_ColumnChart, {
              key: 3,
              categories: _ctx.metric.chart.categories,
              data: _ctx.metric.chart.data
            }, null, 8, ["categories", "data"]))
          : _createCommentVNode("", true)
      ], 64))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.widget.type==='CALENDAR_WEEKLY')
          ? (_openBlock(), _createBlock(_component_CalendarWidget, {
              key: 0,
              widget: _ctx.widget,
              editable: false
            }, null, 8, ["widget"]))
          : _createCommentVNode("", true)
      ], 64))
}