import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row g-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseWidget = _resolveComponent("BaseWidget")!
  const _component_WidgetForm = _resolveComponent("WidgetForm")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgets, (widget) => {
        return (_openBlock(), _createBlock(_component_BaseWidget, {
          key: widget.id,
          widget: widget,
          editable: _ctx.editable,
          onEdit: _ctx.onEdit,
          onDelete: _ctx.onDelete
        }, null, 8, ["widget", "editable", "onEdit", "onDelete"]))
      }), 128))
    ]),
    _createVNode(_component_base_modal, {
      name: _ctx.state.modalName,
      id: "widgetForm",
      ref: "widgetFormRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_WidgetForm, {
          id: _ctx.state.widgetId,
          widget: _ctx.state.model,
          onSaved: _ctx.onSavedResponse
        }, null, 8, ["id", "widget", "onSaved"])
      ]),
      _: 1
    }, 8, ["name"])
  ], 64))
}