
import {defineComponent, ref, watch} from "vue";
import MetricService from "@/core/services/MetricService";
import LineChart from "@/views/metric/chart/LineChart.vue";
import AreaChart from "@/views/metric/chart/AreaChart.vue";
import BarChart from "@/views/metric/chart/BarChart.vue";
import ColumnChart from "@/views/metric/chart/ColumnChart.vue";
import CalendarWidget from "@/views/widget/CalendarWidget.vue";

export default defineComponent({
  name: "WidgetPreview",
  components: {CalendarWidget, ColumnChart, BarChart, AreaChart, LineChart},
  props: {
    widget: {type: Object, required: true}
  },
  setup(props) {
    const metric = ref<any>({});
    const loadMetric = (id) => {
      if (id) {
        MetricService.get(id).then(res => {
          metric.value = res;
        })
      } else {
        metric.value = {};
      }
    }
    loadMetric(props.widget.metricIdRequest);
    watch(() => props.widget.metricIdRequest, cb => {
      loadMetric(cb);
    })
    return {
      metric,
    }
  }
})
