import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-stretch" }
const _hoisted_2 = {
  key: 0,
  class: "card-body p-0 h-330px"
}
const _hoisted_3 = { class: "flex-grow-1 card-p pb-0" }
const _hoisted_4 = { class: "d-flex flex-stack flex-wrap" }
const _hoisted_5 = { class: "me-2" }
const _hoisted_6 = { class: "fw-bolder text-gray-800 d-block fs-3" }
const _hoisted_7 = { class: "menu-item px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetricName = _resolveComponent("MetricName")!
  const _component_ValueFormat = _resolveComponent("ValueFormat")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_area_chart = _resolveComponent("area-chart")!
  const _component_ColumnChart = _resolveComponent("ColumnChart")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.metric.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_MetricName, { metric: _ctx.metric }, null, 8, ["metric"])
                ])
              ]),
              _createElementVNode("div", {
                class: _normalizeClass([`text-${_ctx.widget.chartConfig.color}`, "fw-bolder fs-3"])
              }, [
                _createVNode(_component_ValueFormat, {
                  type: _ctx.metric.type,
                  value: _ctx.metric.value
                }, null, 8, ["type", "value"])
              ], 2),
              (_ctx.editable)
                ? (_openBlock(), _createBlock(_component_QuickAction, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", {
                          class: "menu-link px-3",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                        }, "Edit"),
                        _createElementVNode("span", {
                          class: "menu-link px-3 text-danger",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDelete && _ctx.onDelete(...args)))
                        }, "Delete")
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.widget.chartConfig.type ==='area')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.metric.id)
                  ? (_openBlock(), _createBlock(_component_area_chart, {
                      key: 0,
                      type: _ctx.widget?.chartConfig.type,
                      "chart-color": _ctx.widget?.chartConfig?.color,
                      "metric-type": _ctx.metric.type,
                      categories: _ctx.metric.chart.categories,
                      data: _ctx.metric.chart.data,
                      "chart-height": 200
                    }, null, 8, ["type", "chart-color", "metric-type", "categories", "data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.widget.chartConfig.type ==='column')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.metric.id)
                  ? (_openBlock(), _createBlock(_component_ColumnChart, {
                      key: 0,
                      categories: _ctx.metric.chart.categories,
                      data: _ctx.metric.chart.data
                    }, null, 8, ["categories", "data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.widget.chartConfig.type ==='line')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (_ctx.metric.id)
                  ? (_openBlock(), _createBlock(_component_LineChart, {
                      key: 0,
                      categories: _ctx.metric.chart.categories,
                      data: _ctx.metric.chart.data
                    }, null, 8, ["categories", "data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.widget.chartConfig.type==='bar')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (_ctx.metric.id)
                  ? (_openBlock(), _createBlock(_component_BarChart, {
                      key: 0,
                      categories: _ctx.metric.chart.categories,
                      data: _ctx.metric.chart.data
                    }, null, 8, ["categories", "data"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}