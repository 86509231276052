
import { defineComponent, ref, watch } from 'vue';
import DocumentService from "@/core/services/DocumentService";

export default defineComponent({
  name: "ImageLargeUpload",
  data() {
    return {
      loading: false,
    }
  },
  props: {
    modelValue: {
      required: true,
    }
  },
  setup(props) {
    const photo = ref(props.modelValue);
    watch(() => props.modelValue, (value) => {
      photo.value = value;
    });
    return {
      photo
    }
  },
  emit: ['update:modelValue'],
  methods: {
    onFileChange(e) {
      this.loading = true;
      const file = e.target.files[0];
      const data = {file: file}
      DocumentService.uploadImage(data).then(res=>{
        this.photo = res.link;
        this.$emit("update:modelValue", this.photo)
      }).finally(()=>{
        this.loading = false;
      })
    },
    onDelete() {
      this.photo = '';
      this.$emit("update:modelValue", this.photo)
    }
  },
})
