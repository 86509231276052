
import {defineComponent, ref, watch} from "vue";
import {GetSpaceId} from "@/core/composite/composite";
import MetricService from "@/core/services/MetricService";
import ValueFormat from "@/views/metric/value/ValueFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import MetricName from "@/views/metric/MetricName.vue";

export default defineComponent({
  name: "StatisticWidget",
  components: {MetricName, QuickAction, ValueFormat},
  props: {
    editable: {default: false},
    widget: {type: Object}
  },
  emits: ['edit', 'delete'],
  setup(props, {emit}) {
    const metric = ref<any>({});
    const loadData = (id) => {
      if (id) {
        MetricService.get(id).then(res => {
          metric.value = res;
        })
      } else {
        metric.value = {};
      }
    }
    watch(() => props?.widget, (cb: any) => {
      if (cb.metricId) {
        loadData(cb?.metricId);
      }
    })
    loadData(props?.widget?.metricId);
    const onEdit = () => {
      emit('edit')
    }
    const onDelete = () => {
      emit('delete')
    }
    return {
      onEdit,
      onDelete,
      metric,
      ...GetSpaceId(),
    }
  }
})
