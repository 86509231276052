
import {defineComponent, ref, watch} from "vue";
import QuickAction from "../../components/base/action/QuickAction.vue";
import moment from "moment";
import WorkService from "@/core/services/WorkService";
import {formatDate} from "@/core/helpers/functions";
import {LoadPanel} from "@/core/composite/composite";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";

export default defineComponent({
  name: "CalendarWidget",
  components: {TaskCompleteStatus, QuickAction},
  props: {
    widget: {required: true, type: Object},
    editable: {default: false},
  },
  emits: ['edit', 'delete'],
  setup(props, {emit}) {
    const onEdit = () => {
      emit('edit')
    }
    const onDelete = () => {
      emit('delete')
    }
    const filter = ref({teamId: props?.widget?.metricIdRequest})
    const loading = ref(false);
    const startOfWeek = moment().startOf('isoWeek').toDate();
    const endOfWeek = moment().endOf('isoWeek').toDate();
    const data = ref<any>([]);
    const loadData = (start, end) => {
      loading.value = true
      WorkService.getWeekly(start, end, filter.value).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    const start = ref(startOfWeek);
    const end = ref(endOfWeek);
    loadData(startOfWeek.getTime(), endOfWeek.getTime())
    watch(() => props.widget.metricIdRequest, (cb) => {
      filter.value.teamId = cb;
      loadData(start.value.getTime(), end.value.getTime())
    })
    return {
      onEdit,
      onDelete,
      loading,
      start,
      end,
      endOfWeek,
      startOfWeek,
      formatDate,
      data,
      ...LoadPanel(),
      loadData,
      filter,
    }
  },
  methods: {
    nextWeek() {
      const st = moment(this.start.getTime())
      const end = moment(this.end.getTime());
      this.start = st.clone().add(7, 'day').toDate()
      this.end = end.clone().add(7, 'day').toDate()
      this.loadData(this.start.getTime(), this.end.getTime())
    },
    previousWeek() {
      const st = moment(this.start.getTime())
      const end = moment(this.end.getTime());
      this.start = st.clone().add(-7, 'day').toDate()
      this.end = end.clone().add(-7, 'day').toDate()
      this.loadData(this.start.getTime(), this.end.getTime())
    },
    buildHeader(start: any) {
      const st = moment(start)
      let array: any = []
      array.push(start);
      array.push(st.clone().add(1, "day").toDate())
      array.push(st.clone().add(2, "day").toDate())
      array.push(st.clone().add(3, "day").toDate())
      array.push(st.clone().add(4, "day").toDate())
      array.push(st.clone().add(5, "day").toDate())
      array.push(st.clone().add(6, "day").toDate())
      return array;
    },

    findTask(date: Date, tasks: any[]) {
      return tasks.find((item) => {
        return moment(date).isSame(new Date(item.dueDate), 'day')
      })
    },

    findSchedule(date, scheduleDate) {
      return moment(date).isSame(new Date(scheduleDate), 'day')
    }

  }
})
