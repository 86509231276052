import {defineStore} from "pinia";
import WidgetService from "@/core/services/WidgetService";

export const useWidgetStore = defineStore('widgetStore', {
  state: () => ({
    list: <any[]>[],
  }),
  actions: {
    async getList(filter) {
      await WidgetService.findAll<any[]>(filter).then(res => {
        this.list = res
      })
    }
  }
})