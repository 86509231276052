import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartWidget = _resolveComponent("ChartWidget")!
  const _component_StatisticWidget = _resolveComponent("StatisticWidget")!
  const _component_ImageWidget = _resolveComponent("ImageWidget")!
  const _component_CalendarWidget = _resolveComponent("CalendarWidget")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`col-sm-`+_ctx.getSize(_ctx.widget.widgetConfig))
  }, [
    (_ctx.widget.type ==='METRIC')
      ? (_openBlock(), _createBlock(_component_ChartWidget, {
          key: 0,
          widget: _ctx.widget,
          editable: _ctx.editable,
          onEdit: _ctx.onEdit,
          onDelete: _ctx.onDelete
        }, null, 8, ["widget", "editable", "onEdit", "onDelete"]))
      : (_ctx.widget.type ==='NUMBER_METRIC')
        ? (_openBlock(), _createBlock(_component_StatisticWidget, {
            key: 1,
            widget: _ctx.widget,
            editable: _ctx.editable,
            onEdit: _ctx.onEdit,
            onDelete: _ctx.onDelete
          }, null, 8, ["widget", "editable", "onEdit", "onDelete"]))
        : _createCommentVNode("", true),
    (_ctx.widget.type ==='IMAGE')
      ? (_openBlock(), _createBlock(_component_ImageWidget, {
          key: 2,
          widget: _ctx.widget,
          editable: _ctx.editable,
          onEdit: _ctx.onEdit,
          onDelete: _ctx.onDelete
        }, null, 8, ["widget", "editable", "onEdit", "onDelete"]))
      : _createCommentVNode("", true),
    (_ctx.widget.type ==='CALENDAR_WEEKLY')
      ? (_openBlock(), _createBlock(_component_CalendarWidget, {
          key: 3,
          widget: _ctx.widget,
          editable: _ctx.editable,
          onEdit: _ctx.onEdit,
          onDelete: _ctx.onDelete
        }, null, 8, ["widget", "editable", "onEdit", "onDelete"]))
      : _createCommentVNode("", true)
  ], 2))
}