
import {defineComponent} from "vue";
import ChartWidget from "@/views/widget/ChartWidget.vue";
import StatisticWidget from "@/views/widget/StatisticWidget.vue";
import ImageWidget from "@/views/widget/ImageWidget.vue";
import CalendarWidget from "@/views/widget/CalendarWidget.vue";

export default defineComponent({
  name: "BaseWidget",
  components: {CalendarWidget, ImageWidget, StatisticWidget, ChartWidget},
  props: {
    editable: {default: false},
    widget: {type: Object}
  },
  emits: ['edit', 'delete'],
  methods: {
    getSize(config) {
      if (config) return config.size;
      return 3
    },
    onEdit() {
      this.$emit('edit', this.widget)
    },
    onDelete() {
      this.$emit('delete', this.widget)
    }
  }
})
